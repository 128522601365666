@import '../../variable.scss';

.cardDetails {
    word-wrap: break-word;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125);
    align-self: center;
    border-radius: 18px;
    height: -webkit-fill-available;

    .divider {
        margin: 0px 20px;
        height: 2px;
        background-color: $divider-color;
    }

    // @media (min-width: 421px) {
    //     margin: 1rem;
    // }

    @media (max-width: 420px) {
        width: 100%;
    }


    .header {
        display: flex;
        justify-content: space-between;

        .MuiCardHeader-action {
            padding: 0.5rem 1rem;
            display: flex;
            gap: 0.5rem;

            @media (max-width: 420px) {
                flex-wrap: wrap;
                padding: 0.5rem 0px;
            }
        }

        @media (max-width: 420px) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }

        .MuiTypography-root {
            font-size: 20px;
            color: $primary-color;
            font-family: 'Degular', sans-serif;
            font-weight: 700;
        }
    }

    .content {
        align-items: center;
        gap: 30px;
        width: -webkit-fill-available;

        @media (max-width: 820px) {
            flex-direction: column;
        }

        .cardContenetItem {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
        }

        .cardContenetList {
            flex: 1;
            width: 100%;
            padding: 1rem;
        }

        .MuiList-root {
            width: 100%;

            .MuiListItem-root {
                font-family: 'Degular', sans-serif;
                letter-spacing: 0px;
                color: $secondary-color;
                opacity: 1;
                font-size: 18px;
                font-weight: 500;
                padding: 5px 0px;

                .MuiListItemSecondaryAction-root {
                    right: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    max-width: 6rem;
                }
            }

            .MuiTypography-root {
                font-family: 'Degular', sans-serif;
                letter-spacing: 0px;
                color: $secondary-color;
                opacity: 1;
                font-size: 18px;
                font-weight: 500;

                @media (max-width: 420px) {
                    max-width: 12rem;
                }
            }
        }
    }


    .MuiCardContent-root:last-child {
        padding-bottom: 0;
    }

    .MuiCardContent-root {
        padding: 0px;
        @media (max-width: 421px) {
            padding-top: 2px;
        }
    }

    .MuiCardHeader-root {
        padding: 8px 16px;
    }

    .MuiCardActions-root {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 820px) {
            justify-content: center;
        }

        .actions {
            gap: 1rem;
            display: flex;

            @media (max-width: 820px) {
                flex-direction: column;
            }
        }
    }
}

.MuiPaper-root.MuiCard-root.cardDetails {
    // overflow: auto;
    height: inherit;
    @media (max-width: 421px) {
        border: none;
        box-shadow: none;
    }
}