@import '../../../variable.scss';

.image-upload-container {
    background-color: $white-smoke-color;
    border: 2px dashed #ccc;
    padding: 5px;
    text-align: center;

    .image-preview-container {
        position: relative;
        height: 10rem;
        margin: 0 auto;
        overflow: hidden;
    }

    .preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .drag-over {
        background-color: #f9f9f9;
    }

    .placeholder {
        padding-left: 1rem;
    }

}