.carousel .carousel-status {
    /* Your styles here */
    position: absolute;
    top: 0;
    right: auto;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.9);
    color: #fff;
    overflow-x: scroll;
  }

  .coordinates {
    margin: 4px 0px;
    font-size: 14px;
    color: #808080;
    margin-bottom: 2px;
    text-align: center;
  }

  .timestamp {
    font-size: 14px;
    color: #808080;
    margin-bottom: 5px;
    text-align: center;
  }