@import '../../../variable.scss';

.inputFieldContainer {
  display: flex;
  flex-direction: column;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.togglePassword {
  position: absolute;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #888888;
}

.togglePassword svg {
  font-size: 1.5rem;
}

.inputField {
    display: block;
    width: 100%;
    height: 50px;
    padding: 5px 20px;
    border: 1px solid $white-color;
    background: $white-color 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 29px;
    font-size: 16px;
  
    &::placeholder {
      color: $place-holder-color;
      font-size: 16px;
      font-weight: 600;
      opacity: 1;
    }
  
    &:focus,
    &:active,
    &:hover {
      border-color: $primary-color;
      box-shadow: 0px 1px 5px #00000029;
      outline: none;
    }
  }

.selectField {
    display: block;
    width: 100%;
    height: 50px;
    padding: 5px 20px;
    border: 1px solid $white-color;
    background: $white-color 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 5px #00000029;
    border-radius: 29px;
    font-size: 16px;
    margin-bottom: 15px;

    option {
      height: 100px;
    }
  
    &::placeholder {
      color: $place-holder-color;
      font-size: 16px;
      font-weight: 600;
      opacity: 1;
    }
  
    &:focus,
    &:active,
    &:hover {
      border-color: $primary-color;
      box-shadow: 0px 1px 5px #00000029;
      outline: none;
    }
  }

  select {
    -webkit-appearance: none;
    appearance: none;
  }

  .select-wrapper {
    position: relative;
  }
  
  .select-wrapper::after {
    content: "▼";
    font-size: 1rem;
    top: 16px;
    right: 10px;
    position: absolute;
    pointer-events: none;
  }

  .select option {
    height: 40px;
  }
  