@import '../../variable.scss';

.sideNav {
    .MuiPaper-root.MuiPaper-elevation1 {
        box-shadow: none;
    }

    .sideBarMenu {
        .menuList {
            a {
                text-decoration: none;
            }

            .collapse {
                background-color: #F2F2F2;
            }

            .active {
                position: relative;
                color: $primary-color;
            }

            .active::before {
                content: '';
                position: absolute;
                border-left: 10px solid $primary-color;
            }

            .MuiListItemIcon-root {
                color: black
            }

            .MuiListItemIcon-root.active-icon {
                fill: $primary-color;
                color: $primary-color;
            }

            .menuLink {
                display: flex;
                align-items: center;

                .MuiTypography-root {
                    color: $black-color;
                    text-align: left;
                    font-family: 'Degular', sans-serif;
                    letter-spacing: 0px;
                    font-size: 14px;
                    font-weight: 700;
                    display: flex;
                    align-items: center;
                }
            }

            .active-link {
                color: $primary-color;
            }

        }
    }
}