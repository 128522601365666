@import '../../variable.scss';

.tracking-content {
    padding: 1rem;
    display: flex;
    gap: 0.5rem;
    width: 100%;


    @media (max-width: 1180px) {
        flex-direction: column;
    }

    .tracking-container {
        width: 40%;
        background-color: #F2F2F2;
        padding: 0.5rem;
        border-radius: 7px;

        .info-text {
            font-size: 14px;
            color: $secondary-color;
            font-weight: 500;
            display: block;
            border-bottom: 1px solid #C3C3C3;
            text-align: left;
            padding: 1rem 0rem;
            font-family:  'Degular', sans-serif;
        }

        @media (max-width: 1180px) {
            width: 100%;
        }

        .search-field {
            display: flex;
            gap: 0.5rem;

            @media (max-width: 1180px) {
                flex-direction: column;
                gap: 0px;
            }

            .button-wrapper {
                padding-top: 2rem;

                @media (max-width: 1180px) {
                    padding-top: 1rem;

                    button {
                        width: 100%;
                    }
                }
            }
        }

        .card-container {
            display: flex;
            flex-direction: column;
            margin: 0.5rem 0rem;
            gap: 0.5rem;

            .icon-container {
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 420px) {
                    /* Invert the icon vertically */
                    transform: rotate(90deg) scaleY(-1);

                }
            }

            .card-module-container {
                display: flex;

                @media (max-width: 1180px) {
                    flex-direction: column;
                    gap: 0.5rem;
                }
            }
        }

        .details-container {
            max-height: 50vh;
            overflow: auto;
        }
    }

    .map-container {
        width: 60%;
        background-color: #F2F2F2;
        border-radius: 7px;

        @media (max-width: 1180px) {
            width: 100%;
            padding: 0px;
        }
    }

    ul {
        opacity: 1;
    }

    .MuiBox-root.css-1wnsr1i {
        width: 100%;
        max-width: 80%;
    }

    .container:before,
    .container::after {
        display: table;
        content: "";
    }

    .container::after {
        clear: both;
    }

    .container {
        width: 100%;
        padding: 15px;
        margin-right: auto;
        margin-left: auto;
        box-sizing: border-box;
    }

    .row {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .row .col-sm-4 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        box-sizing: border-box;
    }

    .col-sm-4 strong {
        text-transform: capitalize;
    }

    .right {
        float: right
    }

    .fill-blue {
        background: #5D48FF;
        color: white;

    }

    .fill-blue span {
        font-size: 15px !important;
        line-height: 1rem !important;
    }

    .shift-right {
        margin-left: 200px;
    }

    .cardModule,
    .css-bhp9pd-MuiPaper-root-MuiCard-root.cardModule {
        box-shadow: 0px 3px 6px -3px rgba(0, 0, 0, 0.16);
        background: #FCFBFC;
        width: 100%;

        .card-content {
            margin: 0px 3px;
            font-family: "degular", sans-serif;
            font-weight: 500;
        }
    }

    .trackingModuleWithTransition {
        border-radius: 12px;
        background: #FCFBFC;
    }

    .trackingModuleWithTransition .css-v6mo1y-MuiTimelineDot-root {
        background-color: #6F7070;
    }

    .trackingModuleWithTransition .css-1ami7lv-MuiTimelineConnector-root {
        background-color: #D0D0D0;
    }

    .transitionIconGrid {
        position: relative;
    }

    .pos-rel {
        position: relative;
    }

    .pos-rel .searchbtn {
        position: absolute;
        right: 0;
        left: auto;
        top: 17px;
        padding: 0;
        opacity: 0;
        height: 62px;
    }

    .pos-rel .searchIcon {
        position: absolute;
        right: 0;
        left: auto;
        top: 17px;
        padding: 0;
        width: 40px;
        height: 60px;
    }

    .pos-rel .searchIcon .MuiSvgIcon-root {
        line-height: 62px;
        height: 100%;
    }

    .pos-rel .searchIcon .MuiSvgIcon-root {
        fill: #5D48FF;
    }

    .filterModule {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        max-width: 100%;
        height: 58px;
        border: 1px solid #ffffff;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 5px #00000029;
        border-radius: 29px;
        position: relative;
    }

    .filterModule span.iconText {
        position: absolute;
        top: 0;
        bottom: 0;
        height: 100%;
        left: 0;
        right: 0;
        text-align: center;
        line-height: 54px;
        color: #5D48FF;
    }

    .filterModule span.iconText span {
        line-height: 0;
        vertical-align: middle;
    }

    .filterModule span.iconText span svg {
        fill: #5D48FF;
    }

    .packageNumberModule .tarackingNumber {
        border: 1px solid #ffffff;
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 5px #00000029;
        border-radius: 0;
        padding: 8px 30px;
        width: auto;
        max-width: fit-content;
        position: relative;
    }

    .packageNumberModule .tarackingNumber:before {
        position: absolute;
        left: 0;
        right: auto;
        top: -1px;
        bottom: -1px;
        width: 20px;
        background: #5D48FF;
        content: "";
    }

    .btnMoreModule {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;

        .moreDetails button {
            border: 1px solid #5D48FF;
            background: transparent 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 5px #00000029;
            border-radius: 29px;
            cursor: pointer;
            font-size: 12px;
            color: #5D48FF;
            line-height: 1.2;
            text-align: center;
            padding: 10px 20px;
        }
    }

    .trackingModuleWithTransition.css-55vwbm-MuiTimeline-root {
        padding: 0;
    }

    .bg-ws-transparent.bg-ws {
        background-color: red;
        height: 70vh;
        overflow: auto;
    }

    .eventTitle .eventTitleText {
        font-size: 14px;
        color: #5D48FF;
        font-weight: 500;
        display: block;
        border-bottom: 1px solid #C3C3C3;
        text-align: left;
        padding: 0 0 20px;
    }

    .noDataContent {
        padding: 16px;
        font-family: "degular", sans-serif;
        font-weight: 500;
    }

    .trackingModuleWithTransition.css-55vwbm-MuiTimeline-root li:nth-child(2) .css-1fe7e6p-MuiTimelineSeparator-root .css-v6mo1y-MuiTimelineDot-root {
        margin: 10px 0 0;
    }

    .trackingModuleWithTransition.css-55vwbm-MuiTimeline-root .css-1fe7e6p-MuiTimelineSeparator-root .css-v6mo1y-MuiTimelineDot-root {
        margin: 0;
    }

    // .trackingModuleWithTransition.css-55vwbm-MuiTimeline-root li:last-child {
    //     padding-bottom: 40px;
    // }
}