
@import '../../../variable.scss';

.tracking-selectField {
    border-top: 1px solid #999999;
    border-bottom: 1px solid #999999;
    border-left: 1px solid #999999;
    border-right: none;
    background-color: #EEEDF9;
    border-radius: 7px 0 0 7px;
    height: 30px;
    width: 100%;
    padding: 0 10px;
  
    &:focus,
    &:active,
    &:hover {
      border-color: none;
      box-shadow: none;
      outline: none;
    }
  }

  select {
    -webkit-appearance: none;
    appearance: none;
    font-family: "degular", sans-serif;
    letter-spacing: 0px;
    color: #888888;
    font-size: 14px;

  }

  .select-input-wrapper {
    position: relative;
    width: 300px;
  }
  