@import 'variable.scss';

.live-tracking-container {
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: 1rem;

    .live-tracking-content {
        // padding: 0rem 1rem;
        display: flex;
        gap: 0.5rem;

        @media (max-width: 1180px) {
            flex-direction: column;
        }

        .tracking-container {
            width: 40%;
            padding: 0.5rem;
            background-color: #F2F2F2;
            border-radius: 7px;

            @media (max-width: 1180px) {
                width: 100%;
            }

            .tracking-detail {
                overflow-y: auto;
                padding: 0.5rem 0px;

                .item {
                    display: flex;
                    gap: 1rem;
                    flex-grow: 1;

                

                    .item-content {
                        font-family: "degular", sans-serif;
                        letter-spacing: 0.43px;
                        color: #999999;
                        opacity: 1;
                        font-size: 10px;
                    }

                    .icon {
                        cursor: pointer;
                    }
                }

                .no-data-text {
                    font-family: "degular", sans-serif;
                    letter-spacing: 0.5px;
                    color: $black-color;
                    opacity: 1;
                    font-size: 12px;
                }
            }
        }

        .map-container {
            width: 60%;
            background-color: #F2F2F2;
            padding: 0.5rem;
            border-radius: 7px;

            @media (max-width: 1180px) {
                width: 100%;
                height: 700px;
                overflow-y: auto;
            }
        }
    }
}

.item-title {
    font-family: "degular", sans-serif;
    letter-spacing: 0.5px;
    color: $black-color;
    opacity: 1;
    font-size: 12px;
}

.package-item-wrapper {
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: space-between;
}

.package-detail-item {
    font-size: 14px;
    color: $secondary-color;
    font-family: "degular", sans-serif;
    align-items: flex-start;
    display: flex;
    flex: 1 1;
    max-width: 50%;
}

.package-detils-row-wrapper {
    display: flex;
    flex-direction: column;
    padding: 5px;
    border: 1px solid gray;
    
}

.package-detils-container {
    margin: 5px;
}