//
// color code variable declaration 
//
$primary-color : #5D48FF;
$secondary-color: #1A1A1A;

$place-holder-color: #888888;
$input-border-color: #707070;
$white-color: #ffffff;
$black-color: #000000;
$white-smoke-color : #EFEFEF ;
$page-title-color: #333333;
$divider-color : #D5D5D5;
$table-header-color : #D9D9D9;
$gray-color : #F2F2F2;
$Success-color : #D0FD5B;