@import 'variable.scss';

.vehilce-container {
    display: flex;
    background: $white-smoke-color;
    margin: 1rem 0rem;
    flex-direction: column;
    align-items: center;

    .title-container {
        height: 40px;
        align-items: center;
        justify-content: center;
        display: flex;

        .title {
            font-family: "degular", sans-serif;
            letter-spacing: 0px;
            opacity: 1;
            font-size: 16px !important;
            font-weight: 400;
        }
    }

}