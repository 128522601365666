.log {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 230px;
    background-color: #e1e7f1;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 12px;
    padding: 5px;
}

.info-icon {
    cursor: pointer;
    margin-left: 5px;
    position: relative;
}

.info-icon::before {
    content: attr(data-tooltip);
    position: absolute;
    top: 120%; 
    right: 0;  
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
    z-index: 1; 
}

.info-icon:hover::before {
    opacity: 1;
    visibility: visible;
}