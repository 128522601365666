@import '../../../variable.scss';

.layout-container {
    height: inherit;
    width: inherit;

    .box-container {
        flex-grow: 1;
        width: 100%;

        @media (min-width: 768px) {
            width: calc(100% - 230px);
        }
    }

    .MuiToolbar-root {
        @media (max-width: 567px) {
            justify-content: space-between;
            flex-direction: row;
            align-items: center;
        }

        @media (min-width: 768px) {
            padding: 0px 20px;
            min-height: 50px;
        }
    }

    .action {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .title-wrapper {
        display: flex;
        align-items: center;
    }

    .title.MuiTypography-root {
        font-size: 20px;
        color: $primary-color;
        font-family: 'Degular', sans-serif;
        font-weight: 700;
    }

    .loginName {
        color: $page-title-color;
        font-family: 'Degular', sans-serif;
        font-weight: 500;
    }

}

.notification-drawer {
    z-index: 1300 !important;
    .ulList {
        padding: 0px;
    }
    .notification {
        display: flex;
            align-items: baseline;
            padding: 0px 10px;
           
            .listItem {
                padding: 2px;

            }
    }
    .message {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

     

        .action {
            display: flex;

        }
    }

}