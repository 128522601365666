@import '../../variable.scss';

.spacer {
    height: 20px;
    /* Adjust the height to control the spacing between belts */
    background-color: black;
    justify-content: center;
    display: flex;

    .label {
        display: inline-block;
        color: white;
    }
}

.wrapper {
    width: inherit;
    margin: 0rem 1rem;
    display: flex;
    flex-direction: column;

    .label-container {
        display: flex;
        justify-content: space-between;   
        font-family: "degular", sans-serif;
        letter-spacing: 0px;
        opacity: 1;
        font-size: 16px;
        color: var(--secondary-color); 
        font-weight: 700;
    }

    .warehouse-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .tote-container {
            width: 45%;
        }

        .roller-cage-image {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            width: 10%;

            .img {
                height: -webkit-fill-available;
                width: -webkit-fill-available;
            }
        }

    }

    // .divider {
    //     // border-left: 20px solid maroon;
    //     // height: 500px;
    //     /* Ensure the divider extends the full height of the conveyor belts */
    //     margin: 0 10px;
    //     /* Adjust the margin to control the spacing between the belts and the divider */
    // }
}