@import '../../variable.scss';

.MuiContainer-maxWidthFull.login-containerFull {
  max-width: 100%;
  width: 100%;
  padding: 0;
}

.MuiGrid-container {
  background: #ffffff;
}

.loginForm {
  position: relative;
}

.loginFormWrapper {
  position: absolute;
  left: 50%;
  right: auto;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loginFormmWrap {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;

  /* Styles for iphone and iPads in both mode */
  @media (min-width: 320px) and (max-width: 480px) {
    padding-top: 0px;
  }

  .codeScanner {
    display: flex;
    width: 100%;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  .verify-otp {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .MuiBox-root {
    width: 100%;
    max-width: 100%;
    display: block;
    margin: auto;
    padding: 0 15px;

    @media (min-width: 1200px) {
      max-width: 90%;
    }
  }

  form {
    margin: 0 auto 0;

    label {
      margin: 0;
      padding: 5px 0 15px;
      display: block;
    }
  }
}

.btnModule {
  display: block;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.radioButton {
  span {
    font-size: 18px;
    font-family: "degular", sans-serif;
  }
}

.loginSupport {
  margin: 0;
  padding: 20px 0 20px;
  text-align: center;
  margin: 0 auto;
  justify-content: center;
  font-size: 18px;
  font-family: "degular", sans-serif;

  a {
    color: $primary-color;
    text-decoration: none;
    font-weight: 600;
  }
}

.forgetLink {
  &::before {
    display: table;
    content: "";
  }

  &::after {
    display: table;
    content: "";
    clear: both;
  }

  display: block;
  width: 100%;
  padding: 0 5px 30px;
  position: relative;
  font-size: 18px;
  font-family: "degular",
  sans-serif;

  @media (min-width: 768px) {
    padding: 10px 5px 10px;
  }

  /* Styles for iphone and iPads in both mode */
  @media (min-width: 320px) and (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  span {

    &.reg-link {
      float: left;
      position: absolute;
      bottom: 15px;

      @media (min-width: 768px) {
        position: relative;
        bottom: 0;
      }
    }

    &.fp-link {
      float: right;
    }
  }

  a {
    text-decoration: none;
    color: $primary-color;
    font-weight: 600;
  }
}