@import '../../variable.scss';

.route-detail-container {
    background-color: lightyellow;
    padding: 0.8rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .route-detail-row {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* 3 items per row */
        gap: 1rem;

        .row-container {
            display: flex;
            align-items: center;
            gap: 10px;
            background-color: var(--component-color);
            height: 40px;
            width: 100%;
            padding: 0px 5px;

            .text {
                font-size: 16px;
                color: $secondary-color;
                font-family: 'Degular', sans-serif;
                letter-spacing: 0.48px;
                font-weight: 500;
                display: contents;

                .count {
                    color: var(--font-color);
                }
            }
        }

        .vertical-bar {
            width: 2px;
            height: 18px;
            background-color: $place-holder-color;
        }
    }

    .button {
        margin-left: auto;
    }
}