@import '../../variable.scss';

.Container {
    align-items: center;
    display: flex; 
    justify-content: center;
    width: 100%;
    height: 80vh;
}

.MapWrapper {
    width: 100%;
    height: 80vh;
    flex-direction: column;
    position: relative;
}

.Wrapper {
    display: flex; 
    width: 100%;
    flex-direction: column;
}

.ChipWrapper {
    padding: 0.5rem 0rem;
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    white-space: nowrap;

    @media (max-width: 600px) {
        flex-direction: row;
        overflow-x: initial;
        flex-wrap: wrap;
    }
}