@import '../../../variable.scss';

.form-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 1rem;

    .form-wrapper {
        display: flex;
        gap: 3rem;

        @media (max-width: 820px) {
            flex-direction: column;
        }
    }

    .field-container {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;


    }

    .textField {
        background-color: $white-color;
        // height: 30px;
    }

    .button-container {
        display: flex;
        gap: 1rem;
        height: 40px;
        align-self: flex-end;

        @media (min-width: 820px) {
            margin-left: auto;
        }
  
    }
}
