.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 'rgba(255, 255, 255, 0.7)';
    display: none; /* Initially hide the overlay */
  }
  
  .loader-overlay.show {
    display: flex; /* Show the overlay when loading is true */
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Set a high z-index to ensure it appears above other elements */
  }
  