@import '../../variable.scss';

.dataTable .MuiTable-root {
    height: 100%;
    
    // /* For normal screens (laptops) */
    // @media (max-width: 1366px) {
    //         height: 55vh;
    // }

    // /* For HD screens */
    // @media (max-width: 2560px) {
    //         height: 76vh;
    // }

    // /* For 4K HD screens */
    // @media (min-width: 2561px) {
    //         height: 90vh;
    // }

    .MuiTableHead-root {
        background-color: $table-header-color;

        .MuiTableCell-head {
            &.table-head-cell {
                background-color: $table-header-color;
                font-size: 12px;
                color: $secondary-color;
                font-family: "degular", sans-serif;
                padding: 0px 5px;
                height: 50px;
                font-weight: 600;
                cursor: pointer;

                .columnWithSort {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    .MuiTableBody-root {
        .MuiTableCell-root {
            &.table-row-cell {
                font-size: 12px;
                color: $secondary-color;
                font-family: "degular", sans-serif;
                height: 3rem;
                padding: 0px 5px;
                font-weight: 500;
                border-bottom: 2px solid rgba(224, 224, 224, 1);
            }
        }
    }
}