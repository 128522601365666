@import '../../../variable.scss';

.listComponent {
    .MuiListItemText-root{
        .MuiTypography-root{
        color: $secondary-color;
        font-family: "degular", sans-serif;
        font-weight: 500;
        }
    }
    .MuiListItemSecondaryAction-root{
        color: $secondary-color;
        font-family: "degular", sans-serif;
        font-weight: 500;
    }
}