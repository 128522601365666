.tableHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    @media (max-width: 420px) {
        justify-content: flex-start;
        flex-direction: column;
    }
}

.header {
    display: flex;
    gap: 0.5rem;
    flex-grow: 1;
}

.action {
    display: flex;
}