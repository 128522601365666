@import '../../../variable.scss';

// Define a mixin for .disabled class
@mixin disabled {
    pointer-events: none; // Disable pointer events to prevent interactions
    opacity: 0.5; // Reduce opacity to visually indicate disabled state
    filter: blur(2px); // Apply a blur effect
}

.base-button {
    border-radius: 29px;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    -webkit-appearance: none;
    color: $white-color;
    padding: var(--padding, 11px 40px);
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border: 0px solid transparent;
    line-height: 1.8;
    margin: 0 auto;
    cursor: pointer;
    font-family: 'Degular', sans-serif;
    align-items: center;
    display: flex;
    justify-content: center;
}

.buton-primary {
    @extend .base-button;
    background-color: var(--color, $primary-color);
    height: var(--height);
}

.buton-primary-square {
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: blue;
    border-radius: 6px;
    color: white;
    border: 0px solid transparent;
    font-size: 0.875rem;
    line-height: 1.75;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    text-decoration: none;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 16px;
    border-radius: 4px;
    cursor: pointer;
  }


.buton-primary-disabled {
    @extend .base-button;
    background-color: var(--color, $primary-color);
    height: var(--height);
    opacity: 0.5; // Reduce opacity to visually indicate disabled state
}

.MuiButtonBase-root.icon-button {
    font-size: 12px;
    font-weight: 500;
    font-family: 'Degular', sans-serif;
    text-transform: none;
    border-radius: 25px;
}