@font-face {
	font-family: 'Navigo ExtraLight';
	src: url('./fonts/Navigo-ExtraLight.eot');
	src: local('Navigo ExtraLight'), local('Navigo-ExtraLight'),
		url('./fonts/Navigo-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Navigo-ExtraLight.woff2') format('woff2'),
		url('./fonts/Navigo-ExtraLight.woff') format('woff'),
		url('./fonts/Navigo-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Navigo Thin';
	src: url('./fonts/Navigo-Thin.eot');
	src: local('Navigo Thin'), local('Navigo-Thin'),
		url('./fonts/Navigo-Thin.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Navigo-Thin.woff2') format('woff2'),
		url('./fonts/Navigo-Thin.woff') format('woff'),
		url('./fonts/Navigo-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Navigo Black';
	src: url('./fonts/Navigo-Black.eot');
	src: local('Navigo Black'), local('Navigo-Black'),
		url('./fonts/Navigo-Black.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Navigo-Black.woff2') format('woff2'),
		url('./fonts/Navigo-Black.woff') format('woff'),
		url('./fonts/Navigo-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Navigo Medium';
	src: url('./fonts/Navigo-Medium.eot');
	src: local('Navigo Medium'), local('Navigo-Medium'),
		url('./fonts/Navigo-Medium.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Navigo-Medium.woff2') format('woff2'),
		url('./fonts/Navigo-Medium.woff') format('woff'),
		url('./fonts/Navigo-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Navigo Light';
	src: url('./fonts/Navigo-Light.eot');
	src: local('Navigo Light'), local('Navigo-Light'),
		url('./fonts/Navigo-Light.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Navigo-Light.woff2') format('woff2'),
		url('./fonts/Navigo-Light.woff') format('woff'),
		url('./fonts/Navigo-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Navigo Regular';
	src: url('./fonts/Navigo-Regular.eot');
	src: local('Navigo Regular'), local('Navigo-Regular'),
		url('./fonts/Navigo-Regular.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Navigo-Regular.woff2') format('woff2'),
		url('./fonts/Navigo-Regular.woff') format('woff'),
		url('./fonts/Navigo-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Navigo Bold';
	src: url('./fonts/Navigo-Bold.eot');
	src: local('Navigo Bold'), local('Navigo-Bold'),
		url('./fonts/Navigo-Bold.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Navigo-Bold.woff2') format('woff2'),
		url('./fonts/Navigo-Bold.woff') format('woff'),
		url('./fonts/Navigo-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

body,
html,
#root {
	font-family: 'Navigo Regular';
	height: 100vh;
	display: flex;
	width: 100%;
}

@media (max-width: 768px) {
    body {
        overflow-x: hidden;
    }
}

/* Add a media query for screens with a max width of 768px */
@media (max-width: 420px) {
	body,
	html,
	#root {
		background-color: white;
		/* Change background color for smaller screens */
	}
}

.buttonModule Button {
	margin: 5px 20px;
}

.MuiTableCell-root {
	font-family: 'Navigo Regular';
}

.bg-ws,
.bg-ws.MuiGrid-container {
	background: #F6F6F6;
}

/* Add this rule to hide the webpack-dev-server-client-overlay */
#webpack-dev-server-client-overlay {
	display: none;
  }