@import '../../../variable.scss';

.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;


  @media (max-width: 1180px) {
    height: inherit;
  }
}

.centered-content {
  text-align: center;
}

.containerLogin {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 16px #00000029;

  @media (min-width: 577px) {
    border-radius: 18px;
  }

  @media (max-width: 420px) {
    height: inherit;
  }

  &.css-1oqqzyl-MuiContainer-root,
  &.css-1qsxih2 {
    height: inherit;
    padding-left: 0;
    padding-right: 0;

    .MuiGrid-container {
      @media (min-width: 577px) {
        border-radius: 18px;
      }
    }

    .MuiGrid-root {
      width: 100%;

      @media (min-width: 577px) {
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
      }

      @media (min-width: 577px) and (max-width: 1180px) {
        height: inherit;
      }
    }

    .MuiGrid-grid-xs-6 {

      &.login-lr-module,
      &.loginForm {
        margin: 0;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
        -ms-flex-positive: 0;
        flex-grow: 0;
        max-width: 100%;
      }
    }
  }

  @media (min-width: 576px) {

    &.css-1oqqzyl-MuiContainer-root,
    &.css-1qsxih2 {

      .MuiGrid-grid-xs-6 {

        &.login-lr-module {
          max-width: 40%;
        }

        &.loginForm {
          max-width: 60%;
        }
      }
    }
  }

  @media (min-width: 768px) {

    &.css-1oqqzyl-MuiContainer-root,
    &.css-1qsxih2 {

      .MuiGrid-grid-xs-6 {

        &.login-lr-module {
          max-width: 50%;
        }

        &.loginForm {
          max-width: 50%;
        }
      }
    }
  }

  @media (min-width: 1280px) {
    margin: 40px;
  }

  @media (min-width: 1680px) {
    margin: 180px auto 180px;
  }
}

.loginBanner,
.loginBanner.login-lr-module.MuiPaper-root {
  width: 100%;
  background-color: $primary-color;
  padding: 10px 15px;

  @media (min-width: 768px) {
    padding: 0;
  }
}

.logo-login {
  height: auto;
  position: relative;

  @media(min-width: 576px) {
    height: 100%;
  }
}

.logo-login img {

  max-width: 140px;

  @media (min-width: 576px) {
    transform: translate(-20%, -50%);
    top: 50%;
    left: 20%;
    position: absolute;
    width: 100%;
    max-width: 185px;
  }

  @media (min-width: 768px) {
    max-width: 303px;
  }
}

.loginBanner.login-lr-module.MuiGrid-root,
.loginBanner.login-lr-module {
  position: relative;

  &::before {
    @media (min-width: 576px) {
      position: absolute;
      left: auto;
      right: -72px;
      top: 0;
      bottom: auto;
      content: "";
      background-image: url(../../../../public/static/images/top-right-8.svg);
      width: 205px;
      height: 317px;
      background-size: 65%;
      background-repeat: no-repeat;
    }

    @media (min-width: 768px) {
      right: 0;
      background-size: 100%;
      background-repeat: no-repeat;
    }


  }

  &::after {
    @media (min-width: 576px) {
      position: absolute;
      left: 0;
      right: auto;
      top: auto;
      bottom: -68px;
      content: "";
      background-image: url(../../../../public/static/images/bottom-left-8.svg);
      height: 192px;
      width: 187px;
      background-size: 65%;
      background-repeat: no-repeat;
    }

    @media (min-width: 768px) {
      bottom: 0;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
}