.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;

}

.react-datepicker__input-container input {
    display: block;
    width: 100%;
    font-size: 14px;
    font-family: 'Degular', sans-serif;
    height: 40px;
    border: 1px solid #E4E4E4;
    border-radius: 7px;
    padding: 10px;

    &::placeholder {
        color: #999;
        font-size: 14px;
    }
}