@import 'variable.scss';

.container {
    width: -webkit-fill-available;
    height: 100%;
    margin: 0.5rem 0.5rem 0rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .content {
        .route-details {
            width: 100%;
            margin: 2rem 0rem;
        }

        .action {
            display: flex;
            gap: 0.2rem;
            cursor: pointer;
        }
    }
}