@import '../../variable.scss';

.add-duty-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 10px;
    border: 1px solid $primary-color;
    width: 24rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    // gap: 0.5rem;
    z-index : 10;
    max-height: 98vh;

    .title {
        font-family: "degular", sans-serif;      
        letter-spacing: 0px;
        color: $primary-color;
        opacity: 1;
        font-size: 25px;
        font-weight: 500;
    }

    .subText{
        display: block;
        letter-spacing: 0px;
        color: $place-holder-color;
        font-size: 12px;
        font-family: "degular", sans-serif;      
        max-width: 18rem;
    }

    .button-container {
        display: flex;
        gap: 1rem;
        margin-top: 20px;

        @media (min-width: 820px) {
            margin-left: auto;
        }
  
    }

    .helper-text {
        display: block;
        letter-spacing: 0px;
        color: $place-holder-color;
        font-size: 14px;
        font-family: "degular", sans-serif;
        font-weight: 400;
    }

}