.selected-marker {
    border: 2px solid red; /* Customize the border style as needed */
  }

  .custom-button {
    position: absolute;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    z-index: 1000;
  }
  
  .custom-button-final {
    position: absolute;
    left: 70px;
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    z-index: 1000;
  }

  .custom-button:hover {
    background-color: #0056b3;
  }  
  
  .custom-button-final:hover {
    background-color: #0056b3;
  }  
  
  
  
  
  