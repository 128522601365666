.FormContainer {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.checkbox-label .MuiTypography-root {
    font-size: 16px;
    font-family: 'Degular', sans-serif;
    font-weight: 400;
}
